var exports = {};

function removeRule(rule) {
  var maxIndex = rule.index;
  var sh = rule.parentStyleSheet;
  var rules = sh.cssRules || sh.rules;
  maxIndex = Math.max(maxIndex, rules.length - 1);

  while (maxIndex >= 0) {
    if (rules[maxIndex] === rule) {
      sh.deleteRule(maxIndex);
      break;
    }

    maxIndex--;
  }
}

exports.removeRule = removeRule;
export default exports;